import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_URLS, TenantId } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as _ from 'lodash';
declare var $: any;
@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css'],
})
export class CourseComponent implements OnInit {
  student_Id: any;
  showDialogReg = false;
  @Input() apnCoursesData: any;
  length: any;

  tenantLink = APP_URLS.FILE_PATH_LINODE;

  constructor(
    public service: CourseService,
    private route: ActivatedRoute,
    private toastr: UserService,
    private router: Router
  ) {
    this.getCourseList();
  }

  customOptions: OwlOptions = {
    loop: false,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: true,
    pullDrag: true,
    mouseDrag: true,
    autoWidth: false,
    margin: 10,
    navText: [
      '<i class="fas fa-chevron-left" aria-hidden="true"></i>',
      '<i class="fas fa-chevron-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: { items: 1 },
      400: { items: 2 },
      740: { items: 3 },
      940: { items: 3 },
      1000: { items: 3 },
    },
    nav: true,
  };

  ngOnInit() {
    this.apnCoursesData = _.groupBy(this.apnCoursesData, 'course_type'); //lodash function
    var userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.student_Id = userDetails ? userDetails._id : '';

    $(function () {
      $('.inx-owl-slide .owl-next').addClass('fas fa-chevron-right');
      $('.inx-owl-slide .owl-prev').addClass('fas fa-chevron-left');
    });
  }

  getCourseList() {
    let url = APP_URLS.Courses;
    this.service.getCall(url).subscribe((res: any) => {
      if (res.courses.length > 0) {
        res.courses.forEach((x) => {
          if (this.student_Id) {
            if (x.favorite) {
              x.Favouritebutton = 'Remove Favourite';
            } else {
              x.Favouritebutton = 'Add As Favourite';
            }
            if (x.applied) {
              x.Applybutton = 'Applied';
            } else {
              x.Applybutton = 'Apply';
            }
          } else {
            x.Favouritebutton = 'Add As Favourite';
            x.Applybutton = 'Apply';
          }
        });
        console.log(res?.courses);
        // this.apnCoursesData = _.groupBy(res.courses, 'course_type');
        this.apnCoursesData = res.courses;
      }
    });
  }

  selectCourse(data) {
    this.service.selectCourseById(data);
    this.service.senddataToHeader(data);
    this.router.navigate(['/' + data.slug]);
  }

  AddFav(data) {
    let url = APP_URLS.applyAndFavourate + data._id + '?favorite=true';
    if (this.student_Id) {
      this.service.putCall(url, {}).subscribe(
        (data: any) => {
          this.toastr.showSuccess(data.respMessage);
          this.getCourseList();
        },
        (err) => {}
      );
    } else {
      this.toastr.showError('Please register or login to continue');
      this.service.selectedcourse(data._id, 'favourite');
      this.router.navigate(['/' + 'register']);
      // this.showDialogReg=true;
    }
  }

  AddApply(data) {
    let url = APP_URLS.applyAndFavourate + data._id + '?applycourse=true';
    if (this.student_Id) {
      this.service.putCall(url, {}).subscribe(
        (data: any) => {
          this.toastr.showSuccess(data.respMessage);
          this.getCourseList();
        },
        (err) => {}
      );
    } else {
      this.toastr.showError('Please register or login to continue');
      this.service.selectedcourse(data._id, 'apply');
      this.router.navigate(['/' + 'register']);
      // this.showDialogReg=true;
    }
  }
}
