import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MyProfileService } from 'src/app/services/my-profile.service';
@Component({
  selector: 'app-progression-otj',
  templateUrl: './progression-otj.component.html',
  styleUrls: ['./progression-otj.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressionOtjComponent implements OnInit {
  isLoading = false;
  isInternalLoading = false;
  isOtjReportLoading = false;
  studentDetail: any;
  courseId = '';
  studentId = '';
  offthejobsDetail: any = {
    otjMinutes: '',
    otjhours: '',
    targetOffTheJobhours: '',
    targetjob: '',
    totalHours: '',
    total_hours: '',
    totaloffthejob: '',
  };
  displayName = '';
  onlineLearningCount = 0;
  MentoringCount = 0;
  CentreCount = 0;
  houseCPDCount = 0;
  totalHours = 0;
  role = '';
  routeRecords: Subscription;

  type = 'included';

  attendanceList_GlhTime = '';
  attendanceList_count = 0;

  otjActivityReport_GlhTime = 0;
  otjActivityReport_count = 0;

  learningActivitiesList: any;
  learningActivitiesTotal: number;

  learningSupportActivitiesList: any;
  learningSupportActivitiesTotal: number;

  practicalTrainingActivitiesList: any;
  practicalTrainingActivitiesTotal: number;

  vleReport: any[] = [];

  excluded_hrs = '0';
  included_hrs = '0';
  otjAchieved = '0';

  options = {};

  constructor(
    public service: CourseService,
    private route: ActivatedRoute,
    private profileSvc: MyProfileService
  ) {
    this.courseId = localStorage.getItem('courseId');
    if (localStorage.getItem('role') == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = userDetails._id;
      this.displayName = userDetails.displayName;
      this.getOfftheJobs();
      this.getOtjReport();
      this.getExcludedReport();
      this.isInternalLoading = true;
      this.role = 'student';
    }

    if (localStorage.getItem('role') == 'employer') {
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getOfftheJobs();
        this.getOtjReport();
        this.getExcludedReport();
        this.getProfileDetails();
      });
      this.role = 'employer';
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem('profilesummaryData')) {
      this.studentDetail = JSON.parse(
        localStorage.getItem('profilesummaryData')
      );

      this.offthejobsDetail['courseDuration'] =
        this.studentDetail?.courseDuration;
      this.offthejobsDetail['numberOfTheWeekscovered'] =
        this.studentDetail?.numberOfTheWeekscovered;
      this.offthejobsDetail['contractedHrsPerWeek'] =
        this.studentDetail?.contractedHrsPerWeek;
    }
  }

  ngOnDestroy() {
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
        this.displayName = this.studentDetail.displayName;
      },
      (err) => {}
    );
  }

  getExcludedReport(): void {
    let url = APP_URLS.v1.otjReport + '?studentId=' + this.studentId;

    const payload = {
      target: {
        type: 'excluded',
      },
    };

    this.service.postCallwithHeaders(url, payload).subscribe((data: any) => {
      this.excluded_hrs = data?.excluded_hrs;
    });
  }

  getOtjReport(): void {
    let url = APP_URLS.v1.otjReport + '?studentId=' + this.studentId;

    const payload = {
      target: {
        type: this.type,
      },
    };

    this.service.postCallwithHeaders(url, payload).subscribe((data: any) => {
      this.isInternalLoading = false;
      this.isOtjReportLoading = false;

      this.included_hrs = data?.included_hrs
        ? data?.included_hrs
        : this.included_hrs;
      this.excluded_hrs = data?.excluded_hrs
        ? data?.excluded_hrs
        : this.excluded_hrs;
      this.otjAchieved = data?.otjAchieved
        ? data?.otjAchieved
        : this.otjAchieved;

      this.attendanceList_GlhTime = data?.attendanceList_GlhTime;
      this.attendanceList_count = data?.attendanceList_count;

      this.otjActivityReport_GlhTime = data?.otjActivityReport_GlhTime;
      this.otjActivityReport_count = data?.otjActivityReport_count;

      if (data?.offthejob_detail?.workAndLearningActivitiesCount) {
        this.learningActivitiesList =
          data?.offthejob_detail?.workAndLearningActivitiesCount?.[
            'Learning Activities'
          ]?.list;
        this.learningActivitiesTotal =
          data?.offthejob_detail?.workAndLearningActivitiesCount?.[
            'Learning Activities'
          ]?.total;

        this.learningSupportActivitiesList =
          data?.offthejob_detail?.workAndLearningActivitiesCount?.[
            'Learning Support Activities'
          ]?.list;
        this.learningSupportActivitiesTotal =
          data?.offthejob_detail?.workAndLearningActivitiesCount?.[
            'Learning Support Activities'
          ]?.total;

        this.practicalTrainingActivitiesList =
          data?.offthejob_detail?.workAndLearningActivitiesCount?.[
            'Practical Training Activities'
          ]?.list;
        this.practicalTrainingActivitiesTotal =
          data?.offthejob_detail?.workAndLearningActivitiesCount?.[
            'Practical Training Activities'
          ]?.total;
      }

      if (data?.vle_report) {
        let arr = [];
        Object.keys(data?.vle_report).forEach((key) => {
          let obj = {};
          obj['key'] = key;
          obj['value'] = data?.vle_report[key];
          arr.push(obj);
        });

        if (arr?.length > 0) {
          this.vleReport = arr;
        } else {
          this.vleReport = [];
        }
      }

      console.log(this.studentDetail?.targetOfTheJobHrs);

      this.options = {
        title: {
          text: 'OTJ Report Chart',
          subtext: '',
          x: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}',
        },
        legend: {
          x: 'center',
          y: 'bottom',
          data: ['OTJ CONTRACTED HOURS', 'OTJ ACHEIVED HOURS'],
        },
        calculable: true,
        series: [
          {
            name: '',
            type: 'pie',
            radius: [30, 110],
            roseType: 'area',
            data: [
              {
                value: this.studentDetail?.targetOfTheJobHrs,
                name: 'OTJ CONTRACTED HOURS',
              },
              { value: this.included_hrs, name: 'OTJ ACHEIVED HOURS' },
            ],
          },
        ],
      };
    });
  }

  getOfftheJobs() {
    this.isLoading = true;
    let url =
      APP_URLS.getOffTheJobs +
      '?need_offthejob_detail=yes&studentId=' +
      this.studentId;
    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoading = false;
        const allOffthejobs = data.offthejob;
        this.offthejobsDetail = data.offthejob_detail;
        if (allOffthejobs?.length > 0) {
          allOffthejobs.forEach((x) => {
            if (x.category == 'In-house CPD') {
              this.houseCPDCount = this.houseCPDCount + 1;
            }
            if (x.category == 'Attendance at College/Centre') {
              this.CentreCount = this.CentreCount + 1;
            }
            if (x.category == 'Online Learning') {
              this.onlineLearningCount = this.onlineLearningCount + 1;
            }
            if (x.category == 'Mentoring') {
              this.MentoringCount = this.MentoringCount + 1;
            }
            if (x.hours) {
              this.totalHours += parseInt(x.hours);
            }
            if (x.created_by && x.created_by.student) {
              x.recordUplodName = x.created_by.student.displayName;
            } else if (x.created_by_select && x.created_by_select.employee) {
              x.recordUplodName = x.created_by_select.employee.name;
            }

            if (!x.status || x.status == '') {
              x.status = 'Under Review';
            }

            x.final_learner_submit = {};
            if (x.learner_submit && x.learner_submit.length) {
              if (x.learner_submit[x.learner_submit.length - 1]) {
                x.final_learner_submit =
                  x.learner_submit[x.learner_submit.length - 1];
              }
            }

            x.final_employee_reviews = {};
            if (x.employee_reviews && x.employee_reviews.length) {
              if (x.employee_reviews[x.employee_reviews.length - 1]) {
                x.final_employee_reviews =
                  x.employee_reviews[x.employee_reviews.length - 1];
              }
            }
          });
        }
      },
      (err) => {}
    );
  }

  typeChange(data) {
    this.type = data;
    this.getOtjReport();
    this.isOtjReportLoading = true;
  }
}
