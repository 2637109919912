import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { EventEmitter } from 'events';
import { Subscription } from 'rxjs';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-otj-table',
  templateUrl: './otj-table.component.html',
  styleUrls: ['./otj-table.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class OtjTableComponent implements OnInit, OnDestroy {
  @Input() tabname: string;
  @Input() courseDetails: any;

  isLoading = false;
  p: any = 1;
  countPagination = 50;
  totalItems: any;

  courseId: any;
  studentId: any;
  allOffthejobs: any;

  editpopup: boolean = false;
  editPopupOpen = 'none';
  showDialogOffthejob: boolean = false;
  showDialogEditOffthejob: boolean = false;
  showReturnOTJDialog: boolean = false;
  editData: any;
  role = '';

  dialogSize = 'max';
  detailOtjReords: Subscription;
  addOtjRecords: Subscription;
  showAcceptOTJDialog: boolean = false;
  otjReturnObj = {};
  otjAcceptObj = {};
  displayName = '';
  studentDetail: any = {};
  constructor(
    public service: CourseService,
    private toast: UserService,
    private profileSvc: MyProfileService,
    private route: ActivatedRoute
  ) {
    this.courseId = localStorage.getItem('courseId');
    this.role = localStorage.getItem('role');
    if (this.role == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = userDetails._id;
      this.displayName = userDetails.displayName;
      this.getOfftheJobs();
    }

    if (this.role == 'employer') {
      this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getOfftheJobs();
        this.getProfileDetails();
      });
    }

    this.detailOtjReords = this.profileSvc
      .closeModalPopupService()
      .subscribe((res) => {
        this.showReturnOTJDialog = false;
        this.showAcceptOTJDialog = false;
        this.getOfftheJobs();
      });

    this.addOtjRecords = this.profileSvc.getPopup().subscribe((data) => {
      this.getOfftheJobs();
    });
  }

  ngOnInit(): void {
    this.getOfftheJobs();
  }

  ngOnDestroy() {
    this.detailOtjReords.unsubscribe();
    this.addOtjRecords.unsubscribe();
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
        this.displayName = this.studentDetail.displayName;
      },
      (err) => {}
    );
  }

  onChangePage(pageOfItems: Array<any>) {
    this.p = pageOfItems;
  }

  doneSubmit(val): void {
    this.getOfftheJobs();
  }
  getOfftheJobs() {
    if (this.tabname) {
      this.isLoading = true;
      let url =
        APP_URLS.getOffTheJobs +
        '?target_tab=' +
        this.tabname +
        '&need_offthejob_detail=no&studentId=' +
        this.studentId;
      this.service.getCallwithHeader(url).subscribe(
        (data: any) => {
          this.isLoading = false;
          this.allOffthejobs = data.target_offthejob;
          this.totalItems = data.target_offthejob.length;
          this.allOffthejobs.forEach((x) => {
            if (x.created_by && x.created_by.student) {
              x.recordUplodName = x.created_by.student.displayName;
            } else if (x.created_by_select && x.created_by_select.employee) {
              x.recordUplodName = x.created_by_select.employee.name;
            }

            if (!x.status || x.status == '') {
              x.status = 'Under Review';
            }

            x.final_learner_submit = {};
            if (x.learner_submit && x.learner_submit.length) {
              if (x.learner_submit[x.learner_submit.length - 1]) {
                x.final_learner_submit =
                  x.learner_submit[x.learner_submit.length - 1];
              }
            }

            x.final_employee_reviews = {};
            if (x.employee_reviews && x.employee_reviews.length) {
              if (x.employee_reviews[x.employee_reviews.length - 1]) {
                x.final_employee_reviews =
                  x.employee_reviews[x.employee_reviews.length - 1];
              }
            }
          });
        },
        (err) => {}
      );
    }
  }

  editPopup(data) {
    this.otjReturnObj = data;
    this.showReturnOTJDialog = true;
  }

  acceptPopup(data) {
    window.scrollTo(0, 0);
    this.otjAcceptObj = data;
    this.showAcceptOTJDialog = true;
  }

  openAddOTJForm() {
    this.showDialogOffthejob = true;
  }
}
