import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { TrainingService } from '../../services/training.service';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-applied-trainings',
  templateUrl: './applied-trainings.component.html',
  styleUrls: ['./applied-trainings.component.css'],
})
export class AppliedTrainingsComponent implements OnInit {
  AppliedTrainingList = [];
  studentId: any;
  constructor(public service: TrainingService, private router: Router) {}

  ngOnInit(): void {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.getAppliedTrainingList();
  }
  getAppliedTrainingList() {
    let url =
      APP_URLS.AppliedTraining +
      '{"id":' +
      `"` +
      this.studentId +
      `"` +
      ',"type":"student"}';
    this.service.getCallWithHeaders(url).subscribe((res: any) => {
      this.AppliedTrainingList = res.details;
    });
  }

  openCourse(Training) {
    localStorage.setItem('courseId', Training.course._id);
    this.router.navigate(['app/Mycourses/', Training.course._id]);
  }
}
