import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { TenantId } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css'],
})
export class ForgotpasswordComponent implements OnInit {
  forget = {
    email: '',
  };
  constructor(
    private router: Router,
    public service: CourseService,
    private userSvc: UserService,
    private profileSvc: MyProfileService
  ) {}

  ngOnInit(): void {}

  submit() {
    let url =
      APP_URLS.forGotPassword + this.forget.email + '&tenantId=' + TenantId;
    this.userSvc.getCall(url).subscribe((data: any) => {
      if (data.respCode) {
        this.userSvc.showSuccess(data.respMessage);
        this.profileSvc.setPopup(false);
      } else {
        this.userSvc.showError(data.respMessage);
      }
    });
  }
}
