import { Component, OnInit } from '@angular/core';
import { APP_URLS, TenantId } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-view-all-courses',
  templateUrl: './view-all-courses.component.html',
  styleUrls: ['./view-all-courses.component.css'],
})
export class ViewAllCoursesComponent implements OnInit {
  CoursesData = [];
  student_Id = '';
  showDialogReg = false;
  CartList = [];
  FavList = [];
  ID: string;

  constructor(
    public service: CourseService,
    private toastr: UserService,
    private router: Router,
    private userservice: UserService
  ) {
    // this.getCourseList()
  }

  ngOnInit() {
    this.getData();
    var profileData = localStorage.getItem('profilesummaryData');
    if (profileData != undefined && typeof profileData == 'string') {
      this.student_Id = JSON.parse(localStorage.getItem('userDetails'))._id;
    }
  }

  getData() {
    let dataofcart = localStorage.getItem('cartdata');
    if (dataofcart != null) {
      this.CartList = dataofcart.split(',');
    }

    // let dataofFav = localStorage.getItem('favdata');
    // if (dataofFav != null) {
    //   this.FavList = dataofFav.split(',')
    // }

    this.getCourseList();
  }

  showType: string = 'all';
  isnoDataFound: boolean = false;
  noDataMsg: string;

  getCourseList() {
    let url = APP_URLS.latestcourse;
    this.service.getCallwithHeader(url).subscribe((res: any) => {
      if (res.all.length > 0) {
        this.CoursesData = [];
        var targetArr = res[this.showType];
        for (let i = targetArr.length - 1; i >= 0; i--) {
          this.CoursesData.push(targetArr[i]);
        }

        this.CoursesData.sort((a, b) => {
          return <any>new Date(b.created) - <any>new Date(a.created);
        });

        if (!this.CoursesData.length) {
          this.isnoDataFound = true;
          if (this.showType == 'all') {
            this.noDataMsg = 'No course(s) found';
          } else if (this.showType == 'applied') {
            this.noDataMsg = 'No course(s) are applied';
          } else if (this.showType == 'assigned') {
            this.noDataMsg = 'No course(s) are approved';
          } else if (this.showType == 'favorites') {
            this.noDataMsg = 'No favourite course(s) found';
          }
        }

        if (!this.student_Id) {
          this.CoursesData.forEach((x) => {
            if (this.CartList.includes(x._id)) {
              x['text_btn'] = 'Waiting for payment';
            }
          });
        }

        // res.all.forEach(x => {

        //   if (this.CartList.includes(x._id)) {
        //     x['incart'] = true
        //   }
        //   else {
        //     x['incart'] = false
        //   }

        //   if(this.FavList.includes(x._id)){
        //     x['infav'] = true
        //   }
        //   else {
        //     x['infav'] = false
        //   }

        //   // if(this.student_Id){
        //   //  if(x.favorites.length>0){
        //   //    x.favorites.forEach(y => {
        //   //      if(y==this.student_Id)
        //   //      {
        //   //        x.Favouritebutton="Remove Favourite"
        //   //      }
        //   //      else{
        //   //        x.Favouritebutton="Add As Favourite"
        //   //      }
        //   //    });
        //   //   }
        //   //   else{
        //   //    x.Favouritebutton="Add As Favourite"
        //   //   }
        //   //    if (x.applications.length > 0) {
        //   //    x.applications.forEach(y => {
        //   //      if (y == this.student_Id) {
        //   //        x.Applybutton = "Remove From Cart"
        //   //      }
        //   //      else {
        //   //        x.Applybutton = "Add To Cart"
        //   //      }
        //   //    });
        //   //  }
        //   //  else {
        //   //    x.Applybutton = "Add To Cart"
        //   //  }
        //   // }
        //   // else{
        //   // x.Favouritebutton = "Add As Favourite"
        //   // x.Applybutton = "Apply"
        //   // }
        // });
        // this.CoursesData = res.all
      }
    });
  }

  selectCourse(data) {
    this.service.selectCourseById(data);
    this.service.senddataToHeader(data);
    this.router.navigate(['app/' + data.slug]);
  }

  addFavorateFun(data) {
    if (this.student_Id) {
      let url = APP_URLS.applyAndFavourate + data + '?favorite=true';

      this.service.putCall(url, {}).subscribe(
        (data: any) => {
          this.userservice.showSuccess(data.respMessage);
          this.service.selectedcourse('', '');
          this.getData();
        },
        (err) => {}
      );
    } else {
      this.toastr.showError('Please register or login to continue');
    }
  }

  // AddFav(data) {
  //   // let url = APP_URLS.applyAndFavourate + data._id + '?favorite=true';

  //   if (this.student_Id) {
  //     let favarry = []
  //     let url = APP_URLS.favmulti
  //     favarry.push(data._id)
  //     let payload = {
  //       courseids : favarry,
  //       target :"add"
  //     }
  //     this.service.putCall(url, payload)
  //       .subscribe((data: any) => {

  //         if(data.suc_arr.length > 0){
  //           this.userservice.showSuccess(data.suc_arr[0]);
  //         }
  //         if(data.err_arr.length > 0){
  //           this.userservice.showError(data.err_arr[0]);
  //         }
  //         this.getData();
  //       }, err => {

  //       })
  //   } else {

  //     // this.userservice.showError('Please register or login to continue');
  //     // this.service.selectedcourse(data._id, "favourite")
  //     // this.showDialogReg = true;
  //     // this.addtofavLocalStorage(data)
  //     this.toastr.showError('Please register or login to continue');
  //   }
  // }

  AddApply(data) {
    let url = APP_URLS.applyAndFavourate + data._id + '?applycourse=true';

    if (this.student_Id) {
      this.service.putCall(url, {}).subscribe(
        (data: any) => {
          this.toastr.showSuccess(data.respMessage);
          this.getCourseList();
        },
        (err) => {}
      );
    } else {
      this.toastr.showError('Please register or login to continue');
      this.service.selectedcourse(data._id, 'apply');
      this.showDialogReg = true;
    }
  }

  addToCart(course) {
    let url = APP_URLS.addtocart + course._id + '?addtocart=true';

    if (localStorage.getItem('token') && this.student_Id) {
      this.service.putCall(url, {}).subscribe(
        (data: any) => {
          if (data.suc_arr.length > 0) {
            this.userservice.showSuccess(data.suc_arr[0]);
            this.userservice.setCart('data');
          }
          if (data.err_arr.length > 0) {
            this.userservice.showError(data.err_arr[0]);
          }
          this.getCourseList();
        },
        (err) => {}
      );
    } else {
      // this.toastr.showError('Please register or login to continue');
      // this.service.selectedcourse(course._id, "addtocart")
      // this.showDialogReg=true;
      this.addtoLocalStorage(course);
    }
  }

  addtoLocalStorage(course) {
    this.CartList.push(course._id);
    localStorage.setItem('cartdata', this.CartList.toString());
    this.userservice.setCart('data');
    this.userservice.showSuccess('Course Successfully added to Your Cart');
    this.getData();
  }

  goToCart() {
    this.router.navigate(['app/Cart']);
  }

  addtofavLocalStorage(course) {
    this.FavList.push(course._id);
    localStorage.setItem('favdata', this.FavList.toString());
    // this.userservice.setCart('data')
    this.userservice.showSuccess('Course Successfully added to Favourite List');
    this.getData();
  }

  removeFav(course) {
    if (this.student_Id) {
      let favarry = [];
      let url = APP_URLS.favmulti;
      favarry.push(course._id);
      let payload = {
        courseids: favarry,
        target: 'remove',
      };
      this.service.putCall(url, payload).subscribe(
        (data: any) => {
          if (data.suc_arr.length > 0) {
            this.userservice.showSuccess(data.suc_arr[0]);
          }
          if (data.err_arr.length > 0) {
            this.userservice.showError(data.err_arr[0]);
          }
          this.getData();
        },
        (err) => {}
      );
    } else {
      this.FavList.forEach((x, i) => {
        if (x == course._id) {
          this.FavList.splice(i, 1);
          let data = this.FavList;
          if (data.length > 0) {
            localStorage.setItem('favdata', data.toString());
          }
          if (data.length == 0) {
            localStorage.removeItem('favdata');
          }

          this.userservice.showSuccess('Removed from Favourite List');
          // this.userservice.setCart("cart")
          this.getData();
        }
      });
    }
  }

  waitingForApproval() {
    this.service.showError(
      'Waiting for Admin approval. Contact your Admin for further information'
    );
  }

  Chapters = [];

  openCourse(Training) {
    localStorage.setItem('courseId', Training._id);
    localStorage.setItem('TrainingName', Training.name);
    localStorage.setItem('uuid', Training.resume_chapter);
    if (Training.apply_status == 'assigned' && Training.resume_chapter == '') {
      this.router.navigate(['app/Mycourses/', Training._id]);
    }
    if (Training.apply_status == 'assigned' && Training.resume_chapter != '') {
      this.ID = localStorage.getItem('courseId');
      let url =
        APP_URLS.newchapters +
        '{"criteria":[{"key":"tenantId","value":"' +
        TenantId +
        '","type":"eq"},{"key":"course","value":"' +
        this.ID +
        '","type":"eq"}]}';
      this.service.getCallwithHeader(url).subscribe(
        (res: any) => {
          this.Chapters = res.cou_overview;
          this.setChaptersData();
        },
        (err) => {}
      );
    }
  }

  FinalChaptersArray = [];

  async setChaptersData() {
    await this.Chapters.forEach((x, i) => {
      x['accordion'] = '#accordion' + i;
      x['accordionID'] = 'accordion' + i;
      x.child.forEach((y, index) => {
        y['childaccordion'] = '#childaccordion' + index;
        y['childaccordionID'] = 'childaccordion' + index;
        if (y.chapter_type == 'lesson') {
          y['icon'] = 'fa fa-book';
        }
        if (y.chapter_type == 'assignment') {
          y['icon'] = 'fa fa-code';
        }
        if (y.chapter_type == 'downloads') {
          y['icon'] = 'fa fa-cloud-download-alt';
        }
        if (y.chapter_type == 'test' || y.chapter_type == 'qatest') {
          y['icon'] = 'fa fa-pencil-alt';
        }
        if (y.chapter_type == 'quiz') {
          y['icon'] = 'fa fa-graduation-cap';
        }
        if (y.chapter_type == 'chapter') {
          y['icon'] = 'fa fa-bars';
        }
      });
      this.FinalChaptersArray.push(x);
      localStorage.setItem(
        'AllChapters',
        JSON.stringify(this.FinalChaptersArray)
      );
      var Trainingid = localStorage.getItem('courseId');
      var TrainingName = localStorage.getItem('TrainingName');
      var resume_chapter = localStorage.getItem('uuid');
      this.router.navigate([
        'app/Mychapter/',
        Trainingid + '-' + resume_chapter,
        TrainingName.replace(/\s/g, '-'),
      ]);
    });
  }
}
