import { AfterViewInit, Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-coursessilde',
  templateUrl: './coursessilde.component.html',
  styleUrls: ['./coursessilde.component.css'],
})
export class CoursessildeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
