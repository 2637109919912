import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TenantId } from '../../Models/url';
import intlTelInput from 'intl-tel-input';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  registerForm: FormGroup;
  submitted = false;
  showDialog = false;
  input: any;
  countryObj = {
    dialCode: '',
  };
  iti: any;
  countryCode = '';

  courseid = '';
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/;

  get f() {
    return this.registerForm.controls;
  }

  constructor(
    public service: CourseService,
    private userSvc: UserService,
    private formBuilder: FormBuilder,
    private route: Router
  ) {
    this.registerForm = this.formBuilder.group({
      type: this.formBuilder.control('', Validators.required),
      fname: this.formBuilder.control('', Validators.required),
      lname: this.formBuilder.control('', Validators.required),
      mname: this.formBuilder.control(''),
      email: this.formBuilder.control('', [
        Validators.required,
        Validators.email,
      ]),
      phone: this.formBuilder.control('', Validators.required),
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input = document.querySelector('#phone');
      this.iti = intlTelInput(this.input);
      this.iti.setCountry('gb');
    }, 1000);
  }

  @HostListener('document:countrychange', ['$event'])
  getCountry(e) {
    this.countryObj = this.iti.getSelectedCountryData();
  }

  keyPress(event: any) {
    if (event.keyCode == 32) {
      event.preventDefault();
    }
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  loginOpen() {
    this.route.navigate(['app/login']);
  }

  register() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    if (this.countryObj?.dialCode !== '') {
      this.countryCode =
        this.countryObj?.dialCode != 'undefined'
          ? this.countryObj?.dialCode
          : this.countryCode;
    }

    var number = this.registerForm
      .get('phone')
      .value.substring(
        this.registerForm.get('phone').value.lastIndexOf('-') + 1
      );

    if (number.length < 5 || number.length > 15) {
      this.userSvc.showError(
        'Mobile number Minimum 5 Numbers and Maximum 15 Numbers '
      );
      return;
    }
    let url = APP_URLS.Register;
    let payload = {
      email: this.registerForm.get('email').value,
      first_name: this.registerForm.get('fname').value,
      middle_name: this.registerForm.get('mname').value,
      last_name: this.registerForm.get('lname').value,
      mobile: '+' + this.countryCode + '-' + number,
      tenantId: TenantId,
      title: this.registerForm.get('type').value,
      from_place: 'signup',
    };

    if (/^[0-9+-]+$/.test(payload.mobile) == false) {
      this.userSvc.showError('Mobile number is not valid');
      return;
    }

    function validateEmail(email) {
      const re =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return re.test(email);
    }

    if (payload.email !== '') {
      if (!validateEmail(payload.email)) {
        this.userSvc.showError('Email is not valid');
        return;
      }
    }

    this.userSvc.postCall(url, payload).subscribe(
      (res: any) => {
        if (res.respCode) {
          this.userSvc.showSuccess(res.respMessage);
          this.registerForm.reset();
          this.ngAfterViewInit();
        } else {
          this.userSvc.showError(res.respMessage);
        }
      },
      (error) => {
        this.userSvc.showError('Register Failed');
      }
    );
    this.submitted = false;
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }
}
