import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventsComponent } from '../app/pages/events/events.component';
import { AboutusComponent } from '../app/pages/aboutus/aboutus.component';

import { TrainingsComponent } from '../app/pages/trainings/trainings.component';
import { BlogComponent } from '../app/pages/blog/blog.component';
import { ContactComponent } from '../app/pages/contact/contact.component';
import { SupportComponent } from './components/support/support.component';
import { SelectcourseComponent } from './pages/selectcourse/selectcourse.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { MytrainingComponent } from './pages/mytraining/mytraining.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { AppliedTrainingsComponent } from './components/applied-trainings/applied-trainings.component';
import { FavouriteTrainingsComponent } from './components/favourite-trainings/favourite-trainings.component';
import { AllTrainingsComponent } from './components/all-trainings/all-trainings.component';
import { CreateCertificatesComponent } from './pages/create-certificates/create-certificates.component';
import { MyCertificatesComponent } from './pages/my-certificates/my-certificates.component';
import { IndividualCourseComponent } from './pages/individual-course/individual-course.component';
import { MycoursesComponent } from './pages/mycourses/mycourses.component';
import { SelectCertificateComponent } from './components/select-certificate/select-certificate.component';
import { CmsComponent } from './components/cms/cms.component';
import { SetpasswordComponent } from './components/setpassword/setpassword.component';
import { CookiepolicyComponent } from './components/cookiepolicy/cookiepolicy.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { LmsInnerComponent } from './components/lms-inner/lms-inner.component';
import { BookInterviewComponent } from './components/book-interview/book-interview.component';
import { ActionCenterComponent } from './components/action-center/action-center.component';
import { AdmissionComponent } from './components/admission/admission.component';
import { ProfileSummaryComponent } from './components/profile-summary/profile-summary.component';
import { ProgressionComponent } from './components/progression/progression.component';
import { CertificatesTabComponent } from './components/certificates-tab/certificates-tab.component';
import { SupportTicketsComponent } from './components/support-tickets/support-tickets.component';
import { ComplaintComponent } from './components/complaint/complaint.component';
import { FeedbacksComponent } from './components/feedbacks/feedbacks.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

import { OpencertificateComponent } from './pages/opencertificate/opencertificate.component';
import { BookInterviewNewComponent } from './components/book-interview-new/book-interview-new.component';
import { BookInterviewTabsComponent } from './components/book-interview-tabs/book-interview-tabs.component';

import { EmployeeHistoryComponent } from './components/employee-history/employee-history.component';
import { PersonalStatementComponent } from './components/personal-statement/personal-statement.component';
import { AdditionalDocumentsComponent } from './components/additional-documents/additional-documents.component';
import { FeeComponent } from './components/fee/fee.component';
import { ChecklistDocumentsComponent } from './components/checklist-documents/checklist-documents.component';
import { QualificationsComponent } from './components/qualifications/qualifications.component';
import { OfferLetterComponent } from './components/offer-letter/offer-letter.component';
import { ViewAllCoursesComponent } from './components/view-all-courses/view-all-courses.component';

import { CartDetailsComponent } from './components/cart-details/cart-details.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { StudywithusComponent } from './components/studywithus/studywithus.component';

import { BlendedlearningComponent } from './components/blendedlearning/blendedlearning.component';
import { TeachersComponent } from './components/teachers/teachers.component';
import { HowtoapplyComponent } from './components/howtoapply/howtoapply.component';
import { DownloadprospectusComponent } from './components/downloadprospectus/downloadprospectus.component';
import { HelpDeskComponent } from './components/help-desk/help-desk.component';
import { AppEmployerProfileComponent } from './components/employer-profile/employer-profile.component';
import { EmployerMyWorkComponent } from './components/employer-my-work/employer-my-work.component';
import { AppEmployerProfileSummaryComponent } from './components/employer-profile-summary/employer-profile-summary.component';
import { EmployerActionCenterComponent } from './components/employer-action-center/employer-action-center.component';
import { EmployerNotificationComponent } from './components/employer-notification/employer-notification.component';
import { MyApprenticeComponent } from './components/my-apprentice/my-apprentice.component';
import { EmployerSupportTicketComponent } from './components/employer-support-ticket/employer-support-ticket.component';
import { EmpMyProjectComponent } from './components/employer-my-project/employer-my-project.component';
import { EmpMyTaskComponent } from './components/emp-my-task/emp-my-task.component';

import { StudentMyWorkComponent } from './components/student-my-work/student-my-work.component';
import { StudentMyWorkSummaryComponent } from './components/student-my-work-summary/student-my-work-summary.component';
import { StudentWalletComponent } from './components/student-wallet/student-wallet.component';
import { StudentKycComponent } from './components/student-kyc/student-kyc.component';
import { StudentWithdrawComponent } from './components/student-withdraw/student-withdraw.component';
import { StudentChannelAccountComponent } from './components/student-channel-account/student-channel-account.component';
import { DownloadCenterComponent } from './components/download-center/download-center.component';
import { EmployerDownloadCenterComponent } from './components/employer-download-center/employer-download-center.component';
import { OtjPanelComponent } from './components/otj/otj-panel/otj-panel.component';
import { AttendanceComponent } from './components/otj/attendance/attendance.component';
import { EmpOtjComponent } from './components/otj/emp-otj/emp-otj.component';
import { MyAtlabComponent } from './components/atlab/my-atlab/my-atlab.component';
import { SprMainComponent } from './components/spr/spr-main/spr-main.component';
import { HomeComponent } from './components/home/home.component';
import { CoursepageComponent } from './components/coursepage/coursepage.component';
import { LoginComponent } from './components/login/login.component';
import { TemplatebaseComponent } from './components/templatebase/templatebase.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';

const routes: Routes = [
  { path: '', redirectTo: '/app', pathMatch: 'full' },
  {
    path: 'app',
    component: TemplatebaseComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'forgot-password', component: ForgotpasswordComponent },
      { path: 'aboutus', component: AboutusComponent },
      { path: 'studywithus', component: StudywithusComponent },
      { path: 'blendedlearning', component: BlendedlearningComponent },
      { path: 'teachers', component: TeachersComponent },
      { path: 'howtoapply', component: HowtoapplyComponent },
      { path: 'downloadprospectus', component: DownloadprospectusComponent },
      { path: 'events', component: EventsComponent },
      { path: 'viewallcourses', component: ViewAllCoursesComponent },
      { path: 'helpdesk', component: HelpDeskComponent },
      { path: 'courses', component: CoursepageComponent },
      { path: 'Courses/:id', component: CoursesComponent },
      { path: 'training', component: TrainingsComponent },
      { path: 'blog', component: BlogComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'support', component: SupportComponent },
      { path: 'course/:id', component: SelectcourseComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'opencertificate', component: OpencertificateComponent },
      { path: 'Offerletter', component: OfferLetterComponent },
      {
        path: 'employer-mywork',
        component: EmployerMyWorkComponent,
        children: [
          { path: 'emp-my-project', component: EmpMyProjectComponent },
          { path: 'emp-my-task', component: EmpMyTaskComponent },
        ],
      },
      { path: 'mytraining', component: MytrainingComponent },
      {
        path: 'employer-profile',
        component: AppEmployerProfileComponent,
        children: [
          {
            path: 'emp-profile-summary',
            component: AppEmployerProfileSummaryComponent,
          },
          {
            path: 'emp-action-center',
            component: EmployerActionCenterComponent,
          },
          { path: 'my-apprentice', component: MyApprenticeComponent },
          {
            path: 'emp-support-ticket',
            component: EmployerSupportTicketComponent,
          },
          {
            path: 'download-center',
            component: EmployerDownloadCenterComponent,
          },
          {
            path: 'emp-notification',
            component: EmployerNotificationComponent,
          },
        ],
      },
      {
        path: 'student-my-work',
        component: StudentMyWorkComponent,
        children: [
          {
            path: 'student-work-summary',
            component: StudentMyWorkSummaryComponent,
          },
          { path: 'student-wallet', component: StudentWalletComponent },
          { path: 'student-kyc', component: StudentKycComponent },
          { path: 'student-withdraw', component: StudentWithdrawComponent },
          {
            path: 'student-channel-account',
            component: StudentChannelAccountComponent,
          },
        ],
      },
      {
        path: 'profile',
        component: ProfileComponent,
        children: [
          { path: '', redirectTo: 'profile-summary', pathMatch: 'full' },
          { path: 'profile-summary', component: ProfileSummaryComponent },
          { path: 'home', component: HomeComponent },
          {
            path: 'admission',
            component: AdmissionComponent,
            children: [
              { path: 'personalDetails', component: ProfileSummaryComponent },
              { path: 'qualification', component: QualificationsComponent },
              { path: 'empHistory', component: EmployeeHistoryComponent },
              {
                path: 'personalStatement',
                component: PersonalStatementComponent,
              },
              {
                path: 'assitionalDoc',
                component: AdditionalDocumentsComponent,
              },
              { path: 'fee', component: FeeComponent },
              { path: 'checklist', component: ChecklistDocumentsComponent },
            ],
          },
          { path: 'bookinterview', component: BookInterviewTabsComponent },
          { path: 'action-center', component: ActionCenterComponent },
          { path: 'attendance', component: AttendanceComponent },
          { path: 'progression', component: ProgressionComponent },
          { path: 'certificate', component: CertificatesTabComponent },
          { path: 'support-ticket', component: SupportTicketsComponent },
          { path: 'complaint', component: ComplaintComponent },
          { path: 'notification', component: NotificationsComponent },
          { path: 'feedback', component: FeedbacksComponent },
        ],
      },
      {
        path: 'myotj',
        component: OtjPanelComponent,
      },
      {
        path: 'myspr',
        component: SprMainComponent,
      },
      {
        path: 'employer-myotj',
        component: EmpOtjComponent,
      },
      {
        path: 'employer-myspr',
        component: SprMainComponent,
      },
      {
        path: 'myatlab',
        component: MyAtlabComponent,
      },

      {
        path: 'employer-myotj/:id',
        component: OtjPanelComponent,
      },
      {
        path: 'employer-myspr/:id',
        component: SprMainComponent,
      },
      { path: 'mytraining', component: MytrainingComponent },

      { path: 'Mycourses/:id', component: MycoursesComponent },
      { path: 'appliedtraining', component: AppliedTrainingsComponent },
      { path: 'favouritetraining', component: FavouriteTrainingsComponent },
      { path: 'allTraingns', component: AllTrainingsComponent },
      { path: 'allcertificates', component: CreateCertificatesComponent },
      { path: 'mycertificates', component: MyCertificatesComponent },
      {
        path: 'selectCertificateDetails/:id',
        component: SelectCertificateComponent,
      },
      { path: 'cms', component: CmsComponent },
      { path: 'setpassword/:id', component: SetpasswordComponent },
      { path: 'setpassword', component: SetpasswordComponent },
      { path: 'cookiepolicy', component: CookiepolicyComponent },
      { path: 'privacypolicy', component: PrivacypolicyComponent },
      { path: 'Mychapter/:id/:name', component: LmsInnerComponent },
      { path: 'Cart', component: CartDetailsComponent },
      { path: 'checkout', component: CheckoutComponent },
      { path: ':id', component: IndividualCourseComponent },
      { path: '**', component: ErrorPageComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
